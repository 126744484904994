import * as THREE from "three";
import { GUI } from "lil-gui";

export const setupLighting = (scene, paintings) => {
	// Ambient light
	const ambientLight = new THREE.AmbientLight(0xffffff, 0.6);
	scene.add(ambientLight);

	const createSpotlight = (x, y, z, intensity, targetPosition) => {
		const spotlight = new THREE.SpotLight(0xffffff, intensity);
		spotlight.position.set(x, y, z);
		spotlight.target.position.copy(targetPosition);
		spotlight.castShadow = true;
		spotlight.angle = 1.57079;
		spotlight.penumbra = 0.2;
		spotlight.decay = 1;
		spotlight.distance = 40;
		spotlight.shadow.mapSize.width = 1024;
		spotlight.shadow.mapSize.height = 1024;

		// Add spotlight and its target to the scene
		scene.add(spotlight);
		scene.add(spotlight.target);

		return spotlight;
	}

	const frontWallSpotlight = createSpotlight(
		0,
		6.7,
		-13,
		10,
		new THREE.Vector3(0, 0, -20)
	);

	const backWallSpotlight = createSpotlight(
		0,
		6.7,
		13,
		10,
		new THREE.Vector3(0, 0, 20)
	);

	const leftWallSpotlight = createSpotlight(
		-13,
		6.7,
		0,
		10,
		new THREE.Vector3(-20, 0, 0)
	);

	const rightWallSpotlight = createSpotlight(
		13,
		6.7,
		0,
		10,
		new THREE.Vector3(20, 0, 0)
	);
};
